:root {
    --primary-color: var(--p-primary-color);
    --primary-contrast-color: var(--p-primary-contrast-color);
    --text-color: var(--p-text-color);
    --text-color-secondary: var(--p-text-muted-color);
    --surface-border: var(--p-content-border-color);
    --surface-card: var(--p-content-background);
    --surface-hover: var(--p-content-hover-background);
    --surface-overlay: var(--p-overlay-popover-background);
    --transition-duration: var(--p-transition-duration);
    --maskbg: var(--p-mask-background);
    --content-border-radius: var(--p-content-border-radius);
    --layout-section-transition-duration: 0.2s;
    --element-transition-duration: var(--p-transition-duration);
    --focus-ring-width: var(--p-focus-ring-width);
    --focus-ring-style: var(--p-focus-ring-style);
    --focus-ring-color: var(--p-focus-ring-color);
    --focus-ring-offset: var(--p-focus-ring-offset);
    --focus-ring-shadow: var(--p-focus-ring-shadow);
}
