@media screen and (min-width: 1960px) {
    .layout-main,
    .landing-wrapper {
        width: 100%;
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

@media (min-width: 992px) {
    .layout-wrapper {
        &.layout-overlay {
            .layout-main-container {
                margin-left: 0;
                padding-left: 2rem;
            }

            .layout-sidebar {
                transform: translateX(-100%);
                left: 0;
                top: 0;
                height: 100vh;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-right: 1px solid var(--surface-border);
                transition:
                    transform 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99),
                    left 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
                box-shadow:
                    0px 3px 5px rgba(0, 0, 0, 0.02),
                    0px 0px 2px rgba(0, 0, 0, 0.05),
                    0px 1px 4px rgba(0, 0, 0, 0.08);
            }

            &.layout-overlay-active {
                .layout-sidebar {
                    transform: translateX(0);
                }
            }
        }

        &.layout-static {
            .layout-main-container {
                margin-left: 22rem;
            }

            &.layout-static-inactive {
                .layout-sidebar {
                    transform: translateX(-100%);
                    left: 0;
                }

                .layout-main-container {
                    margin-left: 0;
                    padding-left: 2rem;
                }
            }
        }

        .layout-mask {
            display: none;
        }
    }
}

@media (max-width: 991px) {
    .blocked-scroll {
        overflow: hidden;
    }

    .layout-wrapper {
        .layout-main-container {
            margin-left: 0;
            padding-left: 2rem;
        }

        .layout-sidebar {
            transform: translateX(-100%);
            left: 0;
            top: 0;
            height: 100vh;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            transition:
                transform 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99),
                left 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
        }

        .layout-mask {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 998;
            width: 100%;
            height: 100%;
            background-color: var(--maskbg);
        }

        &.layout-mobile-active {
            .layout-sidebar {
                transform: translateX(0);
            }

            .layout-mask {
                display: block;
            }
        }
    }
}
